import { gql } from '@apollo/client';

export const CREATE_POLL_REPLY = gql`
  mutation createPollReply(
    $pollAnswerId: ID
    $pollQuestionId: ID
    $text: String
    $uuid: String!
  ) {
    createPollReply(
      pollAnswerId: $pollAnswerId
      pollQuestionId: $pollQuestionId
      text: $text
      uuid: $uuid
    )
  }
`;

export const CREATE_POLL_REPLIES = gql`
  mutation createPollReplies($pollAnswerIds: [String!], $uuid: String!) {
    createPollReplies(pollAnswerIds: $pollAnswerIds, uuid: $uuid)
  }
`;
