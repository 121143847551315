import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useUuid = () => {
  const [uuid, setUuid] = useState(null);

  useEffect(() => {
    if (Cookies.get('rgpd-uuid') === 'true') {
      setUuid(null);
      Cookies.set('uuid', '', { expires: 30 });
      return;
    }
    const uuid = Cookies.get('uuid') || uuidv4();
    if (!Cookies.get('uuid')) {
      Cookies.set('uuid', uuid, { expires: 30 });
    }
    setUuid(uuid);
  }, []);

  return uuid;
};

export default useUuid;
